<template>
  <div class="carousel">
    <div class="slider">
      <transition-group name="slide" mode="out-in">
        <img :key="currentImage" :src="currentImage" alt="Wallpaper" class="image" />
      </transition-group>
    </div>
  </div>
</template>

<script>
import Banner from '../assets/banner.jpeg'
export default {
  data() {
    return {
      images: [
        "https://res.cloudinary.com/dpfshvisk/image/upload/v1705582784/Aviator_m8ntf9.png",
        Banner,
        // Add more image URLs as needed
      ],
      currentIndex: 0,
      timer: null,
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndex];
    },
  },
  mounted() {
    this.startCarousel();
  },
  beforeUnmount() {
    this.stopCarousel();
  },
  methods: {
    startCarousel() {
      this.timer = setInterval(() => {
        this.nextImage();
      }, 3000);
    },
    stopCarousel() {
      clearInterval(this.timer);
    },
    nextImage() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
  },
};
</script>

<style>
.carousel {
  width: 100%;
  height: 100%; /* Adjust the height as needed */
  margin-bottom: 15px;
  overflow: hidden;
}

.slider {
  display: flex;
  animation: slideAnimation 3s infinite;
}

@keyframes slideAnimation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.001s;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}

.image {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
</style>
